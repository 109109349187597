import CookieConsent from '@inventis/cookieconsent';
import GoogleTagManager from '@inventis/cookieconsent/dist/plugins/GoogleTagManager';

export default (dialog) => {
    const cookieConsentConfig = {
        hideHelperClass: 'h-hide',
    };

    (new GoogleTagManager(dialog)).forwardEvents();

    if (window.HTMLDialogElement === undefined) {
        // No dialog element supported, load polyfill first
        import('../Dialog/Polyfill.js').then(() => {
            new CookieConsent(dialog, cookieConsentConfig);
        });
    } else {
        new CookieConsent(dialog, cookieConsentConfig);
    }
};
